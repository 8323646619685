import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  EmbeddedViewRef,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core'
import {I18nService} from './i18n.service'
import {Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'

type TranslateFn = (key: string, params?: Record<string, string | number>) => string

interface ViewContext {
  $implicit: TranslateFn
}

@Directive({
  selector: '[cftI18n]',
  standalone: true,
})
export class I18nDirective implements OnInit, OnDestroy {
  private view: EmbeddedViewRef<ViewContext> | undefined
  private readonly destroy$ = new Subject<true>()
  private translationsCache = new Map<string, string>()

  constructor(
    private readonly i18nService: I18nService,
    private readonly tmpl: TemplateRef<ViewContext>,
    private readonly vcr: ViewContainerRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  // To get the type of "*cftI18n" in the host component
  static ngTemplateContextGuard(dir: I18nDirective, ctx: unknown): ctx is ViewContext {
    return true
  }

  ngOnInit() {
    // Since language change reloads the page we only wait for translations to be loaded.
    this.i18nService
      .load(this.i18nService.getActiveLang())
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {
        // Reset translations cache
        this.translationsCache.clear()
        // Attach view template after translations are loaded to avoid flickering
        this.view = this.vcr.createEmbeddedView(this.tmpl, {
          $implicit: this.getTranslationFn(),
        })
        this.cdr.markForCheck()
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  getTranslationFn(): TranslateFn {
    return (key: string, params?: Record<string, string | number>) => {
      const keyWithParams = params ? `${key}${JSON.stringify(params)}` : key

      const cachedTranslation = this.translationsCache.get(keyWithParams)
      if (cachedTranslation) {
        return cachedTranslation
      }

      const translation = this.i18nService.translate(key, params)
      this.translationsCache.set(keyWithParams, translation)
      return translation
    }
  }
}
