import {map} from 'rxjs/operators'
import {BreakpointObserver} from '@angular/cdk/layout'
import {Injectable} from '@angular/core'

export enum Breakpoints {
  sm = '(min-width: 640px)',
  md = '(min-width: 768px)',
  lg = '(min-width: 1024px)',
  xl = '(min-width: 1280px)',
}

@Injectable({providedIn: 'root'})
export class BreakpointService {
  breakpoints$ = this.breakpointObserver.observe([Breakpoints.sm, Breakpoints.md, Breakpoints.lg, Breakpoints.xl]).pipe(
    map(r => ({
      sm: r.breakpoints[Breakpoints.sm],
      md: r.breakpoints[Breakpoints.md],
      lg: r.breakpoints[Breakpoints.lg],
      xl: r.breakpoints[Breakpoints.xl],
      mobile: !r.matches,
    })),
  )

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  isMatched(bp: Breakpoints): boolean {
    return this.breakpointObserver.isMatched(bp)
  }
}
